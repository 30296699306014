<template>
    <h5>{{kat}}</h5>
    <div v-if="vysledky">
        <q-table
            :rows="vysledky"
            :sortBy= vysledky.razeni
            :columns="columns"
            row-key="name"
            :rows-per-page="pocetRadku"
            :rows-per-page-label="'Řádků na stránku'"
            :rows-per-page-options="[pocetRadku, 10, 20, 50]"
        />
    </div>

</template>

<script setup>

//            :sortBy= vysledky.razeni

//imports            
    import { useResults } from '@/stores/vysledky24'
    import { ref, computed } from 'vue'
    import { watch } from 'vue'


// přebírání props
const props = defineProps({
  kat: String,
  vyber: String,
})


// zadání storu
    const results = useResults()

// volání getteru
    const vysledky = computed(() => results.getResultsByType(props.vyber))
    const pocetRadku = ref(50)


// tabulka pořadí
    const rows = ref([])
    const columns = ref([
        { name: 'poradi', label: 'Pořadí', align: 'left', field: row => row.poradi_text },
       
        { name: 'jmeno', label: 'Jméno', align: 'left', field: row => row.jmeno },
        { name: 'prijmeni', label: 'Příjmení', align: 'left', field: row => row.prijmeni },
        { name: 'psi', label: 'Psi', align: 'left', field: row => row.pes1 },
        { name: 'psi', label: 'Psi', align: 'left', field: row => row.pes2 },

        { name: 'cas', label: 'Celkový čas', align: 'left', field: row => row.cas },
    ])


</script>
<style scoped>

</style>