<template>
    <CeleMenu />

    <!--tahle část je vlastní obsah routy-->
    <div class="row justify-around" style="height: 65vh; overflow: auto;">
        <div class="col-12 col-md-11 q-pa-xl" style="text-align: center;">
            <div class="row">
                <div class="col-12">
                    <h3>{{detail.title}}</h3>
                    <div>
                        <h5>Termín konání: {{ detail.termin }}</h5>
                        <h5>Místo konání: {{ detail.misto }}</h5>
                        <h5>Délka tras</h5>
                        <span style="font-size: larger;">
                            long: {{ detail.long }}<br>
                            mid: {{ detail.mid }}<br>
                            short {{ detail.short }}
                        </span>
                    </div>
                </div>
                <div v-if="detail.video" class="col-12 col-md-5">
                    <h5>Video ze závodu</h5>
                    <q-video
                        :ratio="16/9"
                        :src="detail.video"
                    />
                </div>
                <div v-if="detail.fotky" class="col-12 col-md-5">
                    <h5>Fotky ze závodu</h5>
                    <span v-for="galerie in detail.fotky" :key="galerie" style="font-size: larger;">
                        <a :href="galerie.odkaz" target="_blank">{{ galerie.popis }}</a>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 self-end footer item" style="height: 5vh;">
        <FooterSection />
    </div>

</template>

<script setup>

//imports            

    import CeleMenu from '@/components/CeleMenu.vue'
    import FooterSection from '@/components/FooterSection.vue'
    import { useRoute } from 'vue-router'
    import { useMeta } from 'vue-meta'
    import { useShrnuti } from '@/stores/useShrnuti'
    import { ref } from 'vue'
    import { storage } from "@/firebase/config.js"
    import {ref as firebaseRef } from 'firebase/storage'


// SEO
    useMeta({ title: 'Dogtrekking za pokladem Voka IV. z Holštejna - shrnutí ročníku' })

// zadání storu
    const shrnuti = useShrnuti()

// route
    const route = useRoute()

// zadání props
    const props = defineProps(['cesta'])

// získání hlavního přehledu galerie
    const detail = ref(null)
    detail.value = shrnuti.getRocnik(route.params.cesta)


/*
// Funkce pro načtení sponzorů
    const loadSponzors = async() => {
        const sponzors = await Sponzor.getSponsors(route.params.cesta)
        AllSponzors.value = sponzors

        // Pro každý sponzor zavolejte funkci getAdress a aktualizujte adresu obrázku
        for (let i = 0; i < AllSponzors.value.length; i++) {
            const sponzor = AllSponzors.value[i]
            const imageUrl = await Sponzor.getAdress(imagesRef, sponzor.obrazek)
            if (imageUrl) {
            // Pokud se podaří získat adresu obrázku, aktualizujte objekt sponzora
                sponzor.web = imageUrl
            }
        }
    }

// Načtěte sponzory při inicializaci komponenty nebo jinak, kde je to vhodné pro váš projekt
loadSponzors()

*/

</script>

