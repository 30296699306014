import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import News from '../views/News.vue'
import Contact from '../views/Contact.vue'
//import Propozice from '../views/Propozice.vue'
import Prihlasovani from '../views/Prihlasovani.vue'
import Startovka from '../views/Startovka.vue'
import NovyRocnik from '../views/NovyRocnik.vue'
import Fotogalerie  from '../views/Photogallery24.vue'
import Sponzori24  from '../views/rocnik24/Sponzori24.vue'
import Shrnuti  from '../views/Shrnuti.vue'
import vysledky24  from '../views/rocnik24/vysledky24.vue'


import AdminLogin from '../views/admin/Login.vue'
import AdminMain from '../views/admin/Main.vue'
import AdminStats from '../views/admin/Stats.vue'
import AdminEditace from '../views/admin/Editace.vue'
import AdminShow from '../views/admin/ShowRacer.vue'
import AdminNews from '../views/admin/NewNews.vue'
import AdminEditNew from '../views/admin/EditNews.vue'
import Dogs from '../views/admin/Dogs.vue'


import { auth } from '../firebase/config'


const requireAuth = (to, from, next) => {
  let user = auth.currentUser
  if (!user) {
    next({ name: 'adminlogin' })
  } else {
    next()
  }
}


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/novinky',
    name: 'news',
    component: News
  },
  {
    path: '/kontakt',
    name: 'contact',
    component: Contact
  },
  /*
  {
    path: '/propozice',
    name: 'propozice',
    component: Propozice
  },
  */
  {
    path: '/prihlasovani',
    name: 'prihlasovani',
    component: Prihlasovani
  },
  {
    path: '/novyrocnik',
    name: 'novyrocnik',
    component: NovyRocnik
  },

  {
    path: '/startovka',
    name: 'startovka',
    component: Startovka
  },
  {
    path: '/fotogalerie/:cesta',
    name: 'Fotogalerie',
    component: Fotogalerie,
    props: true
  },
  {
    path: '/sponzori/:cesta',
    name: 'Sponzori',
    component: Sponzori24,
    props: true
  },
  {
    path: '/vysledky24',
    name: 'vysledky24',
    component: vysledky24
  },
  {
    path: '/shrnuti/:cesta',
    name: 'shrnuti',
    component: Shrnuti,
    props: true
  },
  

  {
    path: '/admin/login',
    name: 'adminlogin',
    component: AdminLogin

  },
  {
    path: '/admin/main',
    name: 'adminmain',
    component: AdminMain,
    beforeEnter: requireAuth
  },
  {
    path: '/admin/stats',
    name: 'adminstats',
    component: AdminStats,
    beforeEnter: requireAuth
  },
  {
    props: true,
    path: '/admin/:id',
    name: 'admineditace',
    component: AdminEditace,
    beforeEnter: requireAuth
  },
  {
    props: true,
    path: '/admin/show/:id',
    name: 'adminshow',
    component: AdminShow,
    beforeEnter: requireAuth
  },
  {
    path: '/admin/news',
    name: 'adminnews',
    component: AdminNews,
    beforeEnter: requireAuth
  },
  {
    props: true,
    path: '/admin/EditNew/:id',
    name: 'adminEditNew',
    component: AdminEditNew,
    beforeEnter: requireAuth
  },
  {
    path: '/admin/dogs',
    name: 'dogs',
    component: Dogs,
    beforeEnter: requireAuth
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
