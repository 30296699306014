<template>
    <CeleMenu />

    <!--tahle část je vlastní obsah routy-->
    <div class="row justify-around" style="height: 65vh; overflow: auto;">
        <div class="col-12 col-md-11 q-pa-xl" style="text-align: center;">
            <h3>Výsledky roku 2024</h3>
                Vyberte výsledky, které kategorie chcete zobrazit
                <q-select 
                    outlined 
                    v-model="kat" 
                    :options="kategorie" 
                    label="kategorie" 
                    class="col-12 col-md-6"
                />

            <div v-if="vyber.length">
                <vysledkyTab :vyber="vyber" :kat = "kat" />
            </div>

        </div>
    </div>
    <div class="col-12 self-end footer item" style="height: 5vh;">
        <FooterSection />
    </div>

</template>

<script setup>

//imports            

    import CeleMenu from '@/components/CeleMenu.vue'
    import FooterSection from '@/components/FooterSection.vue'
    import vysledkyTab from '@/components/vysledkyTab.vue'
    import { useResults } from '@/stores/vysledky24'

    import { useRoute } from 'vue-router'
    import { useMeta } from 'vue-meta'

    import { ref } from 'vue'
    import { watch } from 'vue'

    import { storage } from "@/firebase/config.js"



// SEO
    useMeta({ title: 'Dogtrekking za pokladem Voka IV. z Holštejna - výsledky ročníku 2024' })

// možnosti kategorie v selectu
    const kategorie = ['long DTW1', 'long DTW2', 'long DTM1', 'long DTM2', 'mid DMW1', 'mid DMW2', 'mid DMM1', 'mid DMM2', 'short']

// vybraná kategorie v selectu
    const kat = ref([])

    // zadání storu
    const results = useResults()

// volání getteru
    const vysledky = ref([]) 


// kategorie, kterou předávám do komponenty
    const vyber = ref('')

//sledování vybrané hodnoty v selectu
watch([kat], ([newValue], [oldValue]) => {
    if (newValue != oldValue) 
    {
    if (newValue === 'long DTW1') {
        vyber.value = 'long_dtw1'
    } else if (newValue === 'long DTW2') {
        vyber.value = 'long_dtw2'
    } else if (newValue === 'long DTM1') {
        vyber.value = 'long_dtm1'
    } else if (newValue === 'long DTM2') {
        vyber.value = 'long_dtm2'
    } else if (newValue === 'mid DMM1') {
        vyber.value = 'mid_dmm1'
    } else if (newValue === 'mid DMM2') {
        vyber.value = 'mid_dmm2'    
    } else if (newValue === 'mid DMW1') {
        vyber.value = 'mid_dmw1'
    } else if (newValue === 'mid DMW2') {
        vyber.value = 'mid_dmw2'
    } else if (newValue === 'short') {
        vyber.value = 'short'

    }  
    /*
    console.log(vyber.value)
    vysledky.value = results.getResultsByType(vyber)
    console.log(vysledky.value)*/
    }
    else {
        kat.value = []
    }
})




</script>
