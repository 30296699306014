import { defineStore } from 'pinia'
//import { storage } from "@/firebase/config.js"
//import {ref as firebaseRef, getDownloadURL } from 'firebase/storage'


export const useResults = defineStore('vysledky24', {
  state: () => ({
 
      long_dtw1: 
        [
          {
            jmeno : 'Veronika',
            prijmeni : 'Fránková',
            pes1 : 'Belatrix chlupatý nesmysl',
            pes2 : '',
            cas : '17:17:00',
            poradi_text : '1',
            razeni : '1',
          },
          {
            jmeno : 'Jana',
            prijmeni : 'Štefková',
            pes1 : 'Artem od Pálavských vršků',
            pes2 : '',
            cas : '18:18:00',
            poradi_text : '2',
            razeni : '2',
          },
          {
            jmeno : 'Luisa',
            prijmeni : 'Wáwrová',
            pes1 : 'Femina Famosa Canis Mayrau',
            pes2 : '',
            cas : '33:38:00',
            poradi_text : '3',
            razeni : '3',
          },
          {
            jmeno : 'Eliška',
            prijmeni : 'Drugdová',
            pes1 : 'Pivníček',
            pes2 : '',
            cas : '33:52:00',
            poradi_text : '4',
            razeni : '4',
          },
          {
            jmeno : 'Naty',
            prijmeni : 'Dvořáková',
            pes1 : 'Pery',
            pes2 : '',
            cas : '34:35:00',
            poradi_text : '5',
            razeni : '5',
          },
          {
            jmeno : 'Nela',
            prijmeni : 'Jandeková',
            pes1 : 'Grey Hunter Miraja',
            pes2 : '',
            cas : 'DNF',
            poradi_text : 'DNF',
            razeni : '6',
          },
          {
            jmeno : 'Monika',
            prijmeni : 'Mundilová',
            pes1 : 'Balera Ruffians Legend',
            pes2 : '',
            cas : 'DNF',
            poradi_text : 'DNF',
            razeni : '7',
          },
        ],
      mid_dmm2: 
        [
          {
          jmeno : 'Adam',
          prijmeni : 'Krutiš',
          pes1 : 'Xtrem Hastrmánek',
          pes2 : '',
          cas : '3:04:00',
          poradi_text : '1',
          razeni : '1',
          },
          {
          jmeno : 'Lukáš',
          prijmeni : 'Ryba',
          pes1 : 'IKAROS od Mlázovické tvrze',
          pes2 : '',
          cas : '4:02:00',
          poradi_text : '2',
          razeni : '2',
          },
          {
          jmeno : 'Milan',
          prijmeni : 'Schaffer',
          pes1 : 'Agi',
          pes2 : '',
          cas : '4:44:00',
          poradi_text : '3',
          razeni : '3',
          },
          {
          jmeno : 'Pavel',
          prijmeni : 'Hlaváček',
          pes1 : 'Akim Adonis',
          pes2 : '',
          cas : '4:55:00',
          poradi_text : '4',
          razeni : '4',
          },
          {
          jmeno : 'Martin',
          prijmeni : 'Svrček',
          pes1 : 'OLAF',
          pes2 : '',
          cas : '5:31:00',
          poradi_text : '5',
          razeni : '5',
          },
          {
          jmeno : 'Radim',
          prijmeni : 'Daňhel',
          pes1 : 'Loki',
          pes2 : '',
          cas : '7:09:00',
          poradi_text : '6',
          razeni : '6',
          },
          {
          jmeno : 'Vít',
          prijmeni : 'Bauer',
          pes1 : 'Honey',
          pes2 : '',
          cas : '8:53:00',
          poradi_text : '7',
          razeni : '7',
          },
          {
          jmeno : 'Vladimír',
          prijmeni : 'Wičar',
          pes1 : 'Chilli',
          pes2 : '',
          cas : '9:54:00',
          poradi_text : '8',
          razeni : '8',
          },
          {
          jmeno : 'Marek',
          prijmeni : 'Koman',
          pes1 : 'Dex Kysucká hviezda',
          pes2 : '',
          cas : '9:55:00',
          poradi_text : '9',
          razeni : '9',
          },
        ],
      mid_dmm1: 
          [
          {
          jmeno : 'Lukáš',
          prijmeni : 'Byrtus',
          pes1 : 'Fina Akpytora',
          pes2 : '',
          cas : '3:39:00',
          poradi_text : '1',
          razeni : '1',
          },
          {
          jmeno : 'Martin',
          prijmeni : 'Očadlík',
          pes1 : 'Raven',
          pes2 : '',
          cas : '3:50:00',
          poradi_text : '2',
          razeni : '2',
          },
          {
          jmeno : 'Lukáš',
          prijmeni : 'Družba',
          pes1 : 'Aisha Dogcentrum\´s Guardian',
          pes2 : '',
          cas : '3:52:00',
          poradi_text : '3',
          razeni : '3',
          },
          {
          jmeno : 'Martin',
          prijmeni : 'Židek',
          pes1 : 'Róza',
          pes2 : '',
          cas : '3:53:00',
          poradi_text : '4',
          razeni : '4',
          },
          {
          jmeno : 'Gabriel',
          prijmeni : 'Bílý',
          pes1 : 'Sky',
          pes2 : '',
          cas : '4:01:00',
          poradi_text : '5',
          razeni : '5',
          },
          {
          jmeno : 'Roman',
          prijmeni : 'Staněk',
          pes1 : 'Foxy pod Všerubskou skálou',
          pes2 : '',
          cas : '5:26:00',
          poradi_text : '6',
          razeni : '6',
          },
          {
          jmeno : 'Lukáš',
          prijmeni : 'Vinklárek',
          pes1 : 'Jerry',
          pes2 : '',
          cas : '5:29:00',
          poradi_text : '7',
          razeni : '7',
          },
          {
          jmeno : 'Matyáš',
          prijmeni : 'Dolák',
          pes1 : 'Kaira Debbie_Ann',
          pes2 : '',
          cas : '7:06:00',
          poradi_text : '8',
          razeni : '8',
          },
          {
          jmeno : 'Kamil',
          prijmeni : 'Cirgl',
          pes1 : 'Ejmy',
          pes2 : '',
          cas : '8:28:00',
          poradi_text : '9',
          razeni : '9',
          },
          {
          jmeno : 'Aleš',
          prijmeni : 'Geppert',
          pes1 : 'Ziggy',
          pes2 : '',
          cas : '8:28:00',
          poradi_text : '9',
          razeni : '10',
          },
          {
          jmeno : 'Jakub',
          prijmeni : 'Staněk',
          pes1 : 'Arny Ledové tlapky naděje',
          pes2 : '',
          cas : '8:51:00',
          poradi_text : '11',
          razeni : '11',
          },
        ],
      long_dtw2: 
        [
          {
          jmeno : 'Lucie',
          prijmeni : 'Šmotková',
          pes1 : 'Keksík',
          pes2 : '',
          cas : '19:27:00',
          poradi_text : '1',
          razeni : '1',
          },
          {
          jmeno : 'Jana',
          prijmeni : 'Jebavá',
          pes1 : 'Bohouš',
          pes2 : '',
          cas : '21:41:00',
          poradi_text : '2',
          razeni : '2',
          },
          {
          jmeno : 'Simona',
          prijmeni : 'Majerová',
          pes1 : 'Airin z Litavské kotliny',
          pes2 : 'Alex',
          cas : '37:35:00',
          poradi_text : '3',
          razeni : '3',
          },
          {
          jmeno : 'Hana',
          prijmeni : 'Křováková',
          pes1 : 'Crazy z Divoké Třešňovky',
          pes2 : '',
          cas : '37:56:00',
          poradi_text : '4',
          razeni : '4',
          },
          {
          jmeno : 'Lenka',
          prijmeni : 'Čiperová',
          pes1 : 'Myš',
          pes2 : '',
          cas : 'DNF',
          poradi_text : 'DNF',
          razeni : '5',
          },
          {
          jmeno : 'Marie',
          prijmeni : 'Payne',
          pes1 : 'Frída',
          pes2 : '',
          cas : 'DNF',
          poradi_text : 'DNF',
          razeni : '6',
          },
          {
          jmeno : 'Kateřina',
          prijmeni : 'Radvanská',
          pes1 : 'Edith Yabalute',
          pes2 : '',
          cas : 'DNF',
          poradi_text : 'DNF',
          razeni : '7',
          },
        ],
      long_dtm2: 
        [
          {
          jmeno : 'Zbyněk',
          prijmeni : 'Adámek',
          pes1 : 'Neo',
          pes2 : '',
          cas : '13:21:00',
          poradi_text : '1',
          razeni : '1',
          },
          {
          jmeno : 'Karel',
          prijmeni : 'Šlajer',
          pes1 : 'Vašek',
          pes2 : '',
          cas : '13:23:00',
          poradi_text : '2',
          razeni : '2',
          },
          {
          jmeno : 'Roman',
          prijmeni : 'Barák',
          pes1 : 'kazan z rudého údolí',
          pes2 : '',
          cas : '18:27:00',
          poradi_text : '3',
          razeni : '3',
          },
          {
          jmeno : 'Aleš',
          prijmeni : 'Novotný',
          pes1 : 'Nymeria',
          pes2 : '',
          cas : '24:00:00',
          poradi_text : '4',
          razeni : '4',
          },
          {
          jmeno : 'Jan',
          prijmeni : 'Šeda',
          pes1 : 'Hick Sideric Miks',
          pes2 : '',
          cas : '27:02:00',
          poradi_text : '5',
          razeni : '5',
          },
          {
          jmeno : 'Jiří',
          prijmeni : 'Egg',
          pes1 : 'Isildur',
          pes2 : '',
          cas : '33:52:00',
          poradi_text : '6',
          razeni : '6',
          },
          {
          jmeno : 'Petr',
          prijmeni : 'Dvořák',
          pes1 : 'Mirani',
          pes2 : '',
          cas : '34:32:00',
          poradi_text : '7',
          razeni : '7',
          },
          {
          jmeno : 'Zdeněk',
          prijmeni : 'Petráš',
          pes1 : 'Potter Harry od Ledové královny',
          pes2 : '',
          cas : '35:53:00',
          poradi_text : '8',
          razeni : '8',
          },
          {
          jmeno : 'Vlastimil',
          prijmeni : 'Rečka',
          pes1 : 'Rebell Rony',
          pes2 : '',
          cas : '35:54:00',
          poradi_text : '9',
          razeni : '9',
          },
          {
          jmeno : 'Michal',
          prijmeni : 'Vejtasa',
          pes1 : 'Dellinka',
          pes2 : 'Karlička',
          cas : '38:52:00',
          poradi_text : '10',
          razeni : '10',
          },
          {
          jmeno : 'Roman',
          prijmeni : 'Polášek',
          pes1 : 'Apu',
          pes2 : '',
          cas : '48:10:00',
          poradi_text : '11',
          razeni : '11',
          },
        ],
      mid_dmw2: 
        [
          {
          jmeno : 'Lenka',
          prijmeni : 'Gútová',
          pes1 : 'Tonic Hastrmánek',
          pes2 : 'Gin z Brníkovské stráně',
          cas : '4:31:00',
          poradi_text : '1',
          razeni : '1',
          },
          {
          jmeno : 'Lucie',
          prijmeni : 'Hübnerova',
          pes1 : 'Cody',
          pes2 : '',
          cas : '5:17:00',
          poradi_text : '2',
          razeni : '2',
          },
          {
          jmeno : 'Radka',
          prijmeni : 'Černá',
          pes1 : 'Shelby Vlčí tlapka',
          pes2 : '',
          cas : '5:55:00',
          poradi_text : '3',
          razeni : '3',
          },
          {
          jmeno : 'Alena',
          prijmeni : 'Rabasová',
          pes1 : 'Fee Bee Melero',
          pes2 : '',
          cas : '5:58:00',
          poradi_text : '4',
          razeni : '4',
          },
          {
          jmeno : 'Jana',
          prijmeni : 'Běhalová',
          pes1 : 'Cyra',
          pes2 : '',
          cas : '6:09:00',
          poradi_text : '5',
          razeni : '5',
          },
          {
          jmeno : 'Eva',
          prijmeni : 'Šišoláková',
          pes1 : 'Koda',
          pes2 : '',
          cas : '6:39:00',
          poradi_text : '6',
          razeni : '6',
          },
          {
          jmeno : 'Andrea',
          prijmeni : 'Kučerová',
          pes1 : 'Hallelujah Hero Niktel',
          pes2 : '',
          cas : '6:40:00',
          poradi_text : '7',
          razeni : '7',
          },
          {
          jmeno : 'Mirka',
          prijmeni : 'Davidová',
          pes1 : 'Torin',
          pes2 : '',
          cas : '6:53:00',
          poradi_text : '8',
          razeni : '8',
          },
          {
          jmeno : 'Klára',
          prijmeni : 'Fremutová',
          pes1 : 'Peed (Sascha vom Bopparder Hamm )',
          pes2 : '',
          cas : '7:08:00',
          poradi_text : '9',
          razeni : '9',
          },
          {
          jmeno : 'Michala',
          prijmeni : 'Kličková',
          pes1 : 'Nara',
          pes2 : '',
          cas : '7:09:00',
          poradi_text : '10',
          razeni : '10',
          },
          {
          jmeno : 'Silvia',
          prijmeni : 'Balogova',
          pes1 : 'Ginevra Eden Severu',
          pes2 : '',
          cas : '7:22:00',
          poradi_text : '11',
          razeni : '11',
          },
          {
          jmeno : 'Vladěna',
          prijmeni : 'Štěpánková',
          pes1 : 'Holly z Krasového kraje',
          pes2 : '',
          cas : '7:42:00',
          poradi_text : '12',
          razeni : '12',
          },
          {
          jmeno : 'Magda',
          prijmeni : 'Maralíková',
          pes1 : 'Žofie',
          pes2 : '',
          cas : '8:08:00',
          poradi_text : '13',
          razeni : '13',
          },
          {
          jmeno : 'Petra',
          prijmeni : 'Staňková',
          pes1 : 'Calypso Northern Shadows',
          pes2 : 'Démon',
          cas : '8:51:00',
          poradi_text : '14',
          razeni : '14',
          },
          {
          jmeno : 'Milena',
          prijmeni : 'Bauerová',
          pes1 : 'Isla Bonita',
          pes2 : '',
          cas : '8:53:00',
          poradi_text : '15',
          razeni : '15',
          },
          {
          jmeno : 'Ivana',
          prijmeni : 'Válková',
          pes1 : 'Bella',
          pes2 : '',
          cas : '9:17:00',
          poradi_text : '16',
          razeni : '16',
          },
          {
          jmeno : 'Gabriela',
          prijmeni : 'Čejková',
          pes1 : 'Cita Cele Brita',
          pes2 : '',
          cas : '9:19:00',
          poradi_text : '17',
          razeni : '17',
          },
          {
          jmeno : 'Michaela',
          prijmeni : 'Hanáková',
          pes1 : 'Becky',
          pes2 : '',
          cas : '9:21:00',
          poradi_text : '18',
          razeni : '18',
          },
          {
          jmeno : 'Tereza',
          prijmeni : 'Hoffmannová',
          pes1 : 'Birdie Canadian Rosse',
          pes2 : '',
          cas : '9:22:00',
          poradi_text : '19',
          razeni : '19',
          },
          {
          jmeno : 'Hana',
          prijmeni : 'Bartoňková',
          pes1 : 'Arwenka',
          pes2 : '',
          cas : '9:54:00',
          poradi_text : '20',
          razeni : '20',
          },
          {
          jmeno : 'Jana',
          prijmeni : 'Wičarová',
          pes1 : 'Bárt',
          pes2 : '',
          cas : '9:54:00',
          poradi_text : '20',
          razeni : '21',
          },
          {
          jmeno : 'Kristína',
          prijmeni : 'Komanová',
          pes1 : 'Bella Dona Kysucká hviezda',
          pes2 : '',
          cas : '9:56:00',
          poradi_text : '22',
          razeni : '22',
          },
        ],
      mid_dmw1: 
        [
          {
          jmeno : 'Martina',
          prijmeni : 'Šimková',
          pes1 : 'Kaira',
          pes2 : '',
          cas : '4:10:00',
          poradi_text : '1',
          razeni : '1',
          },
          {
          jmeno : 'Magdaléna',
          prijmeni : 'Wojatschke',
          pes1 : 'Akim',
          pes2 : '',
          cas : '4:11:00',
          poradi_text : '2',
          razeni : '2',
          },
          {
          jmeno : 'Hana',
          prijmeni : 'Hrbková',
          pes1 : 'Falco',
          pes2 : '',
          cas : '4:13:00',
          poradi_text : '3',
          razeni : '3',
          },
          {
          jmeno : 'Kateřina',
          prijmeni : 'Pavlovská',
          pes1 : 'Joy Sia Husky Ostrowya',
          pes2 : 'Shadow',
          cas : '5:08:00',
          poradi_text : '4',
          razeni : '4',
          },
          {
          jmeno : 'Kristýna',
          prijmeni : 'Rädischová',
          pes1 : 'Baboo Z Kyjovic',
          pes2 : '',
          cas : '6:13:00',
          poradi_text : '5',
          razeni : '5',
          },
          {
          jmeno : 'Daniela',
          prijmeni : 'Petrů',
          pes1 : 'Pazienza Amore Puffo B. Gs Phantasia',
          pes2 : '',
          cas : '7:05:00',
          poradi_text : '6',
          razeni : '6',
          },
          {
          jmeno : 'Barbora',
          prijmeni : 'Vašíčková',
          pes1 : 'Dayko',
          pes2 : '',
          cas : '7:13:00',
          poradi_text : '7',
          razeni : '7',
          },
          {
          jmeno : 'Daniela',
          prijmeni : 'Kučerová',
          pes1 : 'Remy',
          pes2 : '',
          cas : '8:04:00',
          poradi_text : '8',
          razeni : '8',
          },
          {
          jmeno : 'Hana',
          prijmeni : 'Tylová',
          pes1 : 'Andy chlupatý nesmysl',
          pes2 : '',
          cas : '8:12:00',
          poradi_text : '9',
          razeni : '9',
          },
          {
          jmeno : 'Jana',
          prijmeni : 'Mašnéglová',
          pes1 : 'Nixie',
          pes2 : '',
          cas : '8:49:00',
          poradi_text : '10',
          razeni : '10',
          },
          {
          jmeno : 'Petra',
          prijmeni : 'Stupková',
          pes1 : 'Drop od Heaven Bress',
          pes2 : '',
          cas : '9:49:00',
          poradi_text : '11',
          razeni : '11',
          },
          {
          jmeno : 'Viktorie',
          prijmeni : 'Zemánková',
          pes1 : 'Anzo od Bruzovického kopce',
          pes2 : '',
          cas : '9:49:00',
          poradi_text : '11',
          razeni : '12',
          },
        ],
      long_dtm1: 
        [
          {
          jmeno : 'Jiří',
          prijmeni : 'Tyl',
          pes1 : 'Bella',
          pes2 : '',
          cas : '21:31:00',
          poradi_text : '1',
          razeni : '1',
          },
          {
          jmeno : 'Vojtěch',
          prijmeni : 'Rečka',
          pes1 : 'Heidi Grey Wolf',
          pes2 : '',
          cas : '26:42:00',
          poradi_text : '2',
          razeni : '2',
          },
          {
          jmeno : 'Jakub',
          prijmeni : 'Kovář',
          pes1 : 'Thessaloniki of Kim’s dream',
          pes2 : '',
          cas : '29:45:00',
          poradi_text : '3',
          razeni : '3',
          },
          {
          jmeno : 'Roman',
          prijmeni : 'Brabec',
          pes1 : 'Joe',
          pes2 : 'Corin',
          cas : 'DNF',
          poradi_text : 'DNF',
          razeni : '4',
          },
          {
          jmeno : 'Petr',
          prijmeni : 'Navrátil',
          pes1 : 'Iapethos Hardy Workaholic',
          pes2 : '',
          cas : 'DNF',
          poradi_text : 'DNF',
          razeni : '5',
          },
          {
          jmeno : 'Lukas',
          prijmeni : 'Vrajik',
          pes1 : 'Ariana of Whisky Vidnavská záře',
          pes2 : '',
          cas : 'DNF',
          poradi_text : 'DNF',
          razeni : '6',
          },
        ],
      short: 
        [
          {
          jmeno : 'Petra',
          prijmeni : 'Remešová',
          pes1 : 'Energia Edita Leawil _ Enie',
          pes2 : '',
          cas : '3:46:00',
          poradi_text : '1',
          razeni : '1',
          },
          {
          jmeno : 'Jana',
          prijmeni : 'Svozilová',
          pes1 : 'Jackie',
          pes2 : '',
          cas : '3:46:00',
          poradi_text : '2',
          razeni : '2',
          },
          {
          jmeno : 'Michaela',
          prijmeni : 'Janáková',
          pes1 : 'Artemis ze Strakaté pavučinky',
          pes2 : '',
          cas : '3:47:00',
          poradi_text : '3',
          razeni : '3',
          },
          {
          jmeno : 'Anna',
          prijmeni : 'Krejčíková',
          pes1 : 'Andante Barcai Fine',
          pes2 : 'Albi Do_Lu_Cha',
          cas : '4:13:00',
          poradi_text : '4',
          razeni : '4',
          },
          {
          jmeno : 'Helena',
          prijmeni : 'Jelínková',
          pes1 : 'Eša Z Lipovského háje',
          pes2 : '',
          cas : '4:18:00',
          poradi_text : '5',
          razeni : '5',
          },
          {
          jmeno : 'Helena',
          prijmeni : 'Jochová',
          pes1 : 'Devils',
          pes2 : '',
          cas : '4:30:00',
          poradi_text : '6',
          razeni : '6',
          },
          {
          jmeno : 'Zdeněk',
          prijmeni : 'Sikora',
          pes1 : 'Eros Elizarova',
          pes2 : '',
          cas : '4:30:00',
          poradi_text : '7',
          razeni : '7',
          },
          {
          jmeno : 'Edita/Aneta',
          prijmeni : 'Staňková/Němcová',
          pes1 : 'Grand Prix Jednička',
          pes2 : 'Fizzy Jednička/Prixi',
          cas : '4:38:00',
          poradi_text : '8',
          razeni : '8',
          },
          {
          jmeno : 'Olga',
          prijmeni : 'Bulashevich',
          pes1 : 'Wolverin s Divokou krví',
          pes2 : '',
          cas : '4:57:00',
          poradi_text : '9',
          razeni : '9',
          },
          {
          jmeno : 'Miroslav',
          prijmeni : 'Rožek',
          pes1 : 'Loki',
          pes2 : '',
          cas : '5:00:00',
          poradi_text : '10',
          razeni : '10',
          },
          {
          jmeno : 'Aneta',
          prijmeni : 'Rožková',
          pes1 : 'Dori',
          pes2 : '',
          cas : '5:00:00',
          poradi_text : '11',
          razeni : '11',
          },
          {
          jmeno : 'Eliška',
          prijmeni : 'Hotovcová',
          pes1 : 'Buddy',
          pes2 : '',
          cas : '5:02:00',
          poradi_text : '12',
          razeni : '12',
          },
          {
          jmeno : 'Veronika',
          prijmeni : 'Urbanova',
          pes1 : 'Houba',
          pes2 : '',
          cas : '5:04:00',
          poradi_text : '13',
          razeni : '13',
          },
          {
          jmeno : 'Jan',
          prijmeni : 'Jirsa',
          pes1 : 'Kišan',
          pes2 : '',
          cas : '5:11:00',
          poradi_text : '14',
          razeni : '14',
          },
          {
          jmeno : 'Petra',
          prijmeni : 'Jirsová',
          pes1 : 'Merlin',
          pes2 : '',
          cas : '5:11:00',
          poradi_text : '15',
          razeni : '15',
          },
          {
          jmeno : 'Martina',
          prijmeni : 'Geiersbergová',
          pes1 : 'Beky ze Seninky',
          pes2 : '',
          cas : '5:13:00',
          poradi_text : '16',
          razeni : '16',
          },
          {
          jmeno : 'Romana',
          prijmeni : 'Tarnoczy',
          pes1 : 'Meggie',
          pes2 : '',
          cas : '5:13:00',
          poradi_text : '17',
          razeni : '17',
          },
          {
          jmeno : 'Petra',
          prijmeni : 'Klimosz',
          pes1 : 'Úno Forte',
          pes2 : '',
          cas : '5:16:00',
          poradi_text : '18',
          razeni : '18',
          },
          {
          jmeno : 'Monika',
          prijmeni : 'Murányi',
          pes1 : 'Žofka',
          pes2 : '',
          cas : '5:16:00',
          poradi_text : '19',
          razeni : '19',
          },
          {
          jmeno : 'Roman',
          prijmeni : 'Mikeš',
          pes1 : 'Gaudí Mantis',
          pes2 : '',
          cas : '5:19:00',
          poradi_text : '20',
          razeni : '20',
          },
          {
          jmeno : 'Lucie',
          prijmeni : 'Kratochvílová',
          pes1 : 'Enny',
          pes2 : '',
          cas : '5:23:00',
          poradi_text : '21',
          razeni : '21',
          },
          {
          jmeno : 'Pavel',
          prijmeni : 'Prokop',
          pes1 : 'Chelsea',
          pes2 : '',
          cas : '5:23:00',
          poradi_text : '22',
          razeni : '22',
          },
          {
          jmeno : 'Petr',
          prijmeni : 'Fuk',
          pes1 : 'Mates',
          pes2 : '',
          cas : '5:28:00',
          poradi_text : '23',
          razeni : '23',
          },
          {
          jmeno : 'Zuzana',
          prijmeni : 'Ivanová',
          pes1 : 'Kira',
          pes2 : '',
          cas : '5:30:00',
          poradi_text : '24',
          razeni : '24',
          },
          {
          jmeno : 'Kateřina',
          prijmeni : 'Fabiánová',
          pes1 : 'Nuttahhun',
          pes2 : '',
          cas : '5:49:00',
          poradi_text : '25',
          razeni : '25',
          },
          {
          jmeno : 'Andrea',
          prijmeni : 'Fabiánová',
          pes1 : 'Ragnar',
          pes2 : '',
          cas : '5:49:00',
          poradi_text : '26',
          razeni : '26',
          },
          {
          jmeno : 'Marek',
          prijmeni : 'Matlocha',
          pes1 : 'Becky',
          pes2 : '',
          cas : '6:33:00',
          poradi_text : '27',
          razeni : '27',
          },
          {
          jmeno : 'Lada',
          prijmeni : 'Procházková',
          pes1 : 'Becky',
          pes2 : '',
          cas : '6:33:00',
          poradi_text : '28',
          razeni : '28',
          },
          {
          jmeno : 'Lenka',
          prijmeni : 'Doláková',
          pes1 : 'Cipísek Velvet Arera',
          pes2 : '',
          cas : '6:55:00',
          poradi_text : '29',
          razeni : '29',
          },
          {
          jmeno : 'Zuzana',
          prijmeni : 'Adamcová',
          pes1 : 'Ares Ritter von Falkenberg',
          pes2 : '',
          cas : '6:55:00',
          poradi_text : '30',
          razeni : '30',
          },
          {
          jmeno : 'Petra',
          prijmeni : 'Krajíčková',
          pes1 : 'Coffi London´s Brandy',
          pes2 : 'Mája',
          cas : '6:57:00',
          poradi_text : '31',
          razeni : '31',
          },
          {
          jmeno : 'Dagmar',
          prijmeni : 'Navrátilová',
          pes1 : 'Charlie',
          pes2 : '',
          cas : '7:00:00',
          poradi_text : '32',
          razeni : '32',
          },
          {
          jmeno : 'Renáta',
          prijmeni : 'Juřenová',
          pes1 : 'Adaro z Krpole',
          pes2 : '',
          cas : '9:01:00',
          poradi_text : '33',
          razeni : '33',
          },
          {
            jmeno : 'Zuzana',
            prijmeni : 'Vančíková',
            pes1 : 'Panda',
            pes2 : '',
            cas : '9:25:00',
            poradi_text : '34',
            razeni : '34',
          },
        ]
        

  }),

  getters: {
    //získání jedné galerie podle předané param z adresního řádku
    getResultsByType: (state) => (type) => {
      return state[type] || []; // Vrátí odpovídající pole nebo prázdné pole
    },
  },
})
/*
    getSponsors: (state) => {
      return (cesta) => {
        return state.sponzori.filter(sponzor => {return sponzor.cesta === cesta})
      }
    }
    

  },


  actions: {
    //vlastní funkce převedení názvů obrázků na jejich adresy
    async getAdress (imagesRef, nazev) {
      try {
        const spaceRef = firebaseRef(imagesRef, nazev)
        const url = await getDownloadURL(spaceRef)
        return url
      } catch (error) {
        // Zpracujte případné chyby
        console.error('Chyba při získávání adresy obrázku:', error)
        return null // nebo jiná vhodná hodnota v případě chyby
      }
    }

  },





  }
})


*/