<template>

    <CeleMenu />
  
    <!--tahle část je vlastní obsah routy-->
    <div class="row justify-around" style="height: 65vh; overflow: auto;">
      <div class="col-12 col-md-6 q-pa-xl">
        <h3>Ročník 2025</h3> 
        <p>V roce 2025 se sejdeme v <b>termínu 18. - 21. 9. 2025</b>.</p>
        <p>Jakmile pro vás budeme mít bližší informace o místě konání, přihlašování, startovkách, sponzorech 
            a upomínkových předmětech, najdete je jako vždy zde na našem webu a 
            stejně tak i na <a href="https://www.facebook.com/HolstejnDogtrekkingVokIV" target="_blank">našem facebooku.</a></p>
      </div>
    </div>
  
    <div class="col-12 self-end footer item" style="height: 5vh;">
      <FooterSection />
    </div>
  
  
  </template>
  
  <script>
  
  import CeleMenu from '@/components/CeleMenu.vue'
  import FooterSection from '@/components/FooterSection.vue'
  
  import { useMeta } from 'vue-meta'
  
  
  export default {
    components: {CeleMenu, FooterSection},
  
    setup(){
      useMeta({ title: 'Dogtrekking za pokladem Voka IV. z Holštejna - nový ročník' })
  
    }
  
  }
  </script>
  