import { defineStore } from 'pinia'
import { storage } from "@/firebase/config.js"
import {ref as firebaseRef, getDownloadURL } from 'firebase/storage'


export const useShrnuti = defineStore('gallery', {
  state: () => {
    return { 
      rocniky: [
        {
            title: 'Shrnutí ročníku 2024',
            cesta: 'rok24',
            termin: '3. - 6. 10. 2024',
            misto: 'Holštejn',
            long: '103,8 km',
            mid: '40 km',
            short: '23,8 km',
            video: 'https://www.youtube.com/embed/D4oVW37uNME',
            fotky: [
                {
                    odkaz: 'https://eu.zonerama.com/Biluska/Album/12213685?fbclid=IwY2xjawHaGSVleHRuA2FlbQIxMQABHW7lqCyRBpsWKqEsBR-8OcEDjNNgqPFlYRHnhCbFeSDbtyISP9orEX8Mjw_aem__-MxcZ07HSm5SzsRCIgOSw',
                    popis: 'od Bilušky'
                }
            ]
        }

      ],

      


     }
  },

  getters: {
    //získání jedné galerie podle předané param z adresního řádku
    getRocnik: (state) => {
      return (cesta) => {
        return state.rocniky.filter(rocnik => {return rocnik.cesta === cesta})[0]
      }
    },
    

  },


    








})