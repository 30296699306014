<template>
  <div class="full-width row wrap justify-start items-center content-center" style="height:30vh">
        <div class="col-12" style="position: relative">
            <div class="lt-sm" style="height:100%">
                <CarouselMaly />
            </div>
            <div class="sm carousel" style="height:100%">
                <CarouselStredni />
            </div>
            <div class="md carousel" style="height:100%">
                <CarouselStredni />
            </div>

            <div class="gt-md carousel" style="height:100%">
                <CarouselVelky />
            </div>
            <CarouselCaption />
        </div>

      <div class = "col-12 menu">
        <!--menu pro malá okna včetně celého q-baru-->
        <div class="lt-sm Qbar bg-accent full-width row wrap justify-between items-center content-center">
          <div class="col-1">
            <VnitrekMalehoMenu :menuItems="menuItems">
            </VnitrekMalehoMenu>
          </div>
        </div>

        <!--menu pro velká okna včetně celého q-baru-->
        <div class="gt-xs items-center Qbar bg-accent menu"  >
            
          <VnitrekMenu :menuItems="menuItems">
          </VnitrekMenu>

        </div>
      </div>
    </div>
</template>

<script>

import VnitrekMenu from './VnitrekMenu.vue'
import VnitrekMalehoMenu from './VnitrekMalehoMenu.vue'
import CarouselMaly from './header/CarouselMaly.vue'
import CarouselStredni from './header/CarouselStredni.vue'
import CarouselVelky from './header/CarouselVelky.vue'
import CarouselCaption from './header/CarouselCaption.vue';




export default {

  components: {
    VnitrekMenu, VnitrekMalehoMenu, CarouselMaly, CarouselStredni, CarouselVelky, CarouselCaption
  },
  setup() {
    const menuItems = [
        {
            name: "Úvod",
            route: "home",
        },
        {
            name: "Novinky",
            route: "news",
        },
        {
            name: "Ročník 2025",
            route: "novyrocnik"
        },
        {name: "Ročník 2024",
            children: [
                {
                    route: "shrnuti", 
                    name: "Shrnutí",
                    param: "rok24"
                },
                {
                    route: "vysledky24", 
                    name: "Výsledky"
                },

                // problém s tím, že dál používám jako <router-link :to="{ name: level1Item.route}"> a params to rozebírá po písmenkách :(
                {
                    route: "Fotogalerie", 
                    param: "trasovani24",
                    name: "Fotky z trasování"
                },
                {
                    route: "Sponzori", 
                    param: "sponzori24",
                    name: "Sponzoři"
                },
            /*
                {
                    route: "prihlasovani", 
                    name: "Přihlašování"
                },

                {
                    route: "startovka", 
                    name: "Startovka"
                },
            */
            ]
        },/*
        {
            name: "Historie",
            children: [
                {
                    name: "Ročník 2015",
                    children: [
                        {
                            route: "home", 
                            name: "Výsledky",
                        },
                        {
                            route: "home", 
                            name: "Fotogalerie",
                        }
                    ]
                },
                {
                    name: "Ročník 2016",
                    children: [
                        {
                            route: "home", 
                            name: "Výsledky",
                        },
                        {
                            route: "home", 
                            name: "Fotogalerie",
                        }
                    ]
                },
                {
                    name: "Ročník 2017",
                    children: [
                        {
                            route: "home", 
                            name: "Výsledky",
                        },
                        {
                            route: "home", 
                            name: "Fotogalerie",
                        }
                    ]
                },
                {
                    name: "Ročník 2018",
                    children: [
                        {
                            route: "home", 
                            name: "Výsledky",
                        },
                        {
                            route: "home", 
                            name: "Fotogalerie",
                        }
                    ]
                },
                {
                    name: "Ročník 2019",
                    children: [
                        {
                            route: "home", 
                            name: "Výsledky",
                        },
                        {
                            route: "home", 
                            name: "Fotogalerie",
                        }
                    ]
                }
            ]
        },*/
        {
            route: "contact",
            name: "Kontakt"
        }
    ]


    return {menuItems}


  }
}

</script>

<style>

</style>